<template>
  <div></div>
</template>

<script>
import Api from "@/assets/js/Api";
import Csrf from "@/assets/js/Csrf";
export default {
  name: "formfailed",
  mounted() {
    let hash = this.$route.params.hash;
    let username = this.$route.params.username;
    setTimeout(() => {
      Csrf.getCookie().then((res) => {
        Api.post("/login", {
          hash: hash,
          username: username,
        }).then((res) => {
          //  console.log("%clogin.vue line:20 res", "color: #007acc;", res);
          localStorage.setItem("participant_token", res.data.token);
          //return;
          if (!res.data.error) {
            // this.$router.push("/streampage");
            
            location.href = "/livestream";
            return;
          }
          if (res.data.error) {
            // this.$router.push("/streampage");

            location.href = "/ticket-sales";
            return;
          }
        });
      });
    }, 2000);
  },
};
</script>
